import countries from './countries'
import policysets from './policysets.js'
import currencies from './currencies'
const currencyOptions = currencies.map(x => x.code)

const countriesOptions = countries.map(x => {
  return { value: x.country, text: x.country }
})

const countriesCodesOptions = countries.map(x => {
  return { value: x.alpha2, text: x.country }
})

countriesOptions.unshift({ value: null, text: 'select country' })
const deleteOptions = [{ value: false, text: 'active' }, { value: true, text: 'deleted' }]
const booleanOptions = [{ value: false, text: 'false' }, { value: true, text: 'true' }]

export default [
  {
    id: 'assetclass',
    model: 'assetclass',
    name: 'Assetclass',
    routeName: 'Assetclasses',
    fields: [
      {
        name: 'name',
        label: 'Name',
        placeholder: 'Name',
        type: 'input'
      }
    ]
  },
  {
    id: 'assurance',
    model: 'assurance',
    name: 'Assurance required',
    routeName: 'Assurance',
    fields: [
      {
        name: 'name',
        label: 'assurance required',
        placeholder: 'yes / no / depends',
        type: 'input'
      }
    ]
  },
  {
    id: 'case',
    model: 'case',
    name: 'Case',
    routeName: 'Case',
    fields: [
      {
        name: 'name',
        label: 'Name',
        placeholder: 'Name',
        type: 'input'
      },
      {
        name: 'description',
        label: 'Description',
        placeholder: 'Details',
        type: 'editor',
        editor: {
          buttons: {
            bold: true,
            italics: true,
            h3: true,
            underline: true,
            ul: true,
            ol: true,
            quote: true,
            undo: true,
            redo: true
          }
        }
      },
      {
        name: 'tailoring',
        label: 'Risk tailoring',
        placeholder: 'Considerations for risk tailoring',
        type: 'editor',
        editor: {
          buttons: {
            bold: true,
            italics: true,
            h3: true,
            underline: true,
            ul: true,
            ol: true,
            quote: true,
            undo: true,
            redo: true
          }
        }
      },
      {
        name: 'products',
        label: 'Products free text',
        placeholder: 'Further details on products involved',
        type: 'textarea',
        rows: 6
      },
      {
        name: 'regulatory',
        label: 'Regulatory sanctions',
        placeholder: 'Details of regulatory sanctions',
        type: 'editor',
        editor: {
          buttons: {
            bold: true,
            italics: true,
            h3: true,
            underline: true,
            ul: true,
            ol: true,
            quote: true,
            undo: true,
            redo: true
          }
        }
      },
      {
        name: 'criminal',
        label: 'Criminal sanctions',
        placeholder: 'Details of criminal sanctions',
        type: 'editor',
        editor: {
          buttons: {
            bold: true,
            italics: true,
            h3: true,
            underline: true,
            ul: true,
            ol: true,
            quote: true,
            undo: true,
            redo: true
          }
        }
      },
      {
        name: 'loss',
        label: 'Loss in USD',
        placeholder: '1,000,000',
        type: 'input',
        validation: {
          method: function (value) {
            return Number.isInteger(Number(value))
          },
          text: 'Needs to be a whole dollar amount (integer)'
        }
      },
      {
        name: 'publication',
        label: 'News',
        type: 'idLookup',
        config: {
          labelLinkingField: 'publication_id',
          labelModel: 'publication',
          linkingModel: 'casepublication',
          name: 'News',
          parentLinkingField: 'case_id',
          relation: 'cases'
        }
      },
      {
        name: 'coverage_id',
        label: 'Coverage',
        selected: 1,
        type: 'selectOneFromDatabase',
        optionsModel: 'coverage'
      },
      {
        name: 'start',
        label: 'Incident start',
        type: 'date'
      },
      {
        name: 'end',
        label: 'Incident end',
        type: 'date'
      },
      {
        name: 'jurisdictions',
        label: 'Jurisdictions',
        type: 'selectList',
        config: {
          labelLinkingField: 'jurisdiction_id',
          labelModel: 'jurisdiction',
          linkingModel: 'casejurisdiction',
          name: 'Jurisdictions',
          nameProperty: 'country',
          parentLinkingField: 'case_id',
          relation: 'cases'
        }
      },
      {
        name: 'assetclasses',
        label: 'Asset classes',
        type: 'selectList',
        config: {
          labelLinkingField: 'assetclass_id',
          labelModel: 'assetclass',
          linkingModel: 'assetclasscase',
          name: 'Asset classes',
          parentLinkingField: 'case_id',
          relation: 'cases'
        }
      },
      {
        name: 'producttypes',
        label: 'Product types',
        type: 'selectList',
        config: {
          labelLinkingField: 'producttype_id',
          labelModel: 'producttype',
          linkingModel: 'caseproducttype',
          name: 'Product types',
          parentLinkingField: 'case_id',
          relation: 'cases'
        }
      },
      {
        name: 'controlrisks',
        label: 'Control risks',
        type: 'selectList',
        config: {
          labelLinkingField: 'controlrisk_id',
          labelModel: 'controlrisk',
          linkingModel: 'casecontrolrisk',
          name: 'Control risks',
          parentLinkingField: 'case_id',
          relation: 'cases'
        }
      },
      {
        name: 'risks',
        label: 'MAR risks',
        type: 'selectList',
        config: {
          labelLinkingField: 'risk_id',
          labelModel: 'risk',
          linkingModel: 'caserisk',
          name: 'MAR risks',
          parentLinkingField: 'case_id',
          relation: 'cases'
        }
      },
      {
        name: 'persons',
        label: 'Persons',
        type: 'selectList',
        config: {
          labelLinkingField: 'person_id',
          labelModel: 'person',
          linkingModel: 'caseperson',
          name: 'Persons',
          parentLinkingField: 'case_id',
          relation: 'cases'
        }
      }
    ]
  },
  {
    id: 'control',
    model: 'control',
    name: 'Control',
    nameProperty: 'name',
    routeName: 'Control',
    ugroupProjectPermission: true,
    fields: [
      {
        name: 'name',
        label: 'Name',
        placeholder: 'Name',
        type: 'input'
      },
      {
        name: 'ugroup_id',
        label: 'Owner - team',
        type: 'selectOneFromDatabaseOwn',
        optionsModel: 'ugroup'
      },
      {
        name: 'objective',
        label: 'Objective',
        placeholder: 'Objective',
        type: 'textarea',
        rows: 6
      },
      {
        name: 'description',
        label: 'Control description',
        placeholder: 'Full details...',
        type: 'editor',
        editor: {
          buttons: {
            bold: true,
            italics: true,
            h3: true,
            underline: true,
            ul: true,
            ol: true,
            quote: true,
            undo: true,
            redo: true
          }
        }
      },
      {
        name: 'theme',
        label: 'Themes',
        type: 'selectList',
        config: {
          labelLinkingField: 'controltheme_id',
          labelModel: 'controltheme',
          linkingModel: 'controlcontroltheme',
          name: 'Themes',
          parentLinkingField: 'control_id',
          relation: 'controls'
        }
      },
      {
        name: 'risk',
        label: 'Risks',
        type: 'selectList',
        own: true,
        config: {
          labelLinkingField: 'controlrisk_id',
          labelModel: 'controlrisk',
          linkingModel: 'controlcontrolrisk',
          name: 'Risks',
          parentLinkingField: 'control_id',
          relation: 'controls'
        }
      },
      {
        name: 'area',
        label: 'Areas',
        type: 'selectList',
        config: {
          labelLinkingField: 'area_id',
          labelModel: 'area',
          linkingModel: 'areacontrol',
          name: 'Areas',
          parentLinkingField: 'control_id',
          relation: 'controls'
        }
      },
      {
        name: 'project',
        label: 'Projects',
        type: 'selectList',
        config: {
          labelLinkingField: 'faqproject_id',
          labelModel: 'faqproject',
          linkingModel: 'controlfaqproject',
          name: 'Projects',
          parentLinkingField: 'control_id',
          relation: 'controls'
        }
      },
      {
        name: 'impactinitiative',
        label: 'Initiatives',
        type: 'nameLookup',
        config: {
          labelLinkingField: 'impactinitiative_id',
          labelModel: 'impactinitiative',
          linkingModel: 'controlimpactinitiative',
          linkable: true,
          linkRouteName: 'ImpactInitiative',
          name: 'Initiatives',
          nameProperty: 'name',
          parentLinkingField: 'control_id',
          relation: 'controls',
          searchColumn: 'name'
        }
      },
      {
        name: 'obligation',
        label: 'Obligation',
        type: 'idLookup',
        config: {
          labelLinkingField: 'obligation_id',
          labelModel: 'obligation',
          linkingModel: 'controlobligation',
          name: 'Obligations',
          parentLinkingField: 'control_id',
          relation: 'controls'
        }
      }
    ]
  },
  {
    id: 'controlrisk',
    model: 'controlrisk',
    name: 'Control risk',
    nameProperty: 'name',
    routeName: 'ControlRisks',
    fields: [
      {
        name: 'name',
        label: 'Name',
        placeholder: 'Name',
        type: 'input'
      },
      {
        name: 'description',
        label: 'Description',
        placeholder: 'Description',
        type: 'textarea',
        rows: 6
      }
    ]
  },
  {
    id: 'controlstatus',
    model: 'controlstatus',
    name: 'Control status',
    routeName: 'ControlStatuses',
    fields: [
      {
        name: 'name',
        label: 'Name',
        placeholder: 'Name',
        type: 'input'
      }
    ]
  },
  {
    id: 'controltheme',
    model: 'controltheme',
    name: 'Control theme',
    nameProperty: 'name',
    routeName: 'ControlThemes',
    fields: [
      {
        name: 'name',
        label: 'Name',
        placeholder: 'Name',
        type: 'input'
      },
      {
        name: 'description',
        label: 'Description',
        placeholder: 'Description',
        type: 'textarea',
        rows: 6
      }
    ]
  },
  {
    id: 'coverage',
    model: 'coverage',
    name: 'Coverage',
    routeName: 'Coverage',
    fields: [
      {
        name: 'name',
        label: 'Name',
        placeholder: 'Name',
        type: 'input'
      }
    ]
  },
  {
    id: 'doc',
    model: 'doc',
    name: 'Document',
    nameProperty: 'name',
    routeName: 'Doc',
    createdBy: 'created_by',
    updatedAt: 'updated_at',
    updatedBy: 'updated_by',
    workflowCreatedAt: 'workflow_created_at',
    workflowCreatedBy: 'workflow_created_by',
    fields: [
      {
        name: 'name',
        label: 'Name',
        placeholder: 'Name',
        type: 'input',
        validation: {
          method: function (value) {
            if (value) {
              return value.length > 2
            } else {
              return false
            }
          },
          text: 'Needs more than 2 characters'
        }
      },
      {
        name: 'body',
        label: 'Full details',
        placeholder: 'Full details...',
        type: 'editor',
        editor: {
          buttons: {
            bold: true,
            italics: true,
            h2: true,
            h3: true,
            link: true,
            underline: true,
            ul: true,
            ol: true,
            quote: true,
            undo: true,
            redo: true
          }
        }
      },
      {
        name: 'keywords',
        label: 'Key words',
        placeholder: 'keyword;',
        type: 'textarea',
        rows: 3
      },
      {
        name: 'order',
        label: 'Order',
        placeholder: 1,
        type: 'number',
        validation: {
          method: function (value) {
            if (value) {
              return value >= 0 && value <= 10000 && Number.isInteger(Number(value))
            } else {
              return false
            }
          },
          text: 'Needs to be an integer greater than or equal to 0 and smaller than or equal to 10000'
        }
      }
    ]
  },
  {
    id: 'faq',
    model: 'faq',
    name: 'Frequently Asked Question',
    nameProperty: 'question',
    routeName: 'Faq',
    createdBy: 'created_by',
    updatedAt: 'updated_at',
    updatedBy: 'updated_by',
    workflowCreatedAt: 'status_created_at',
    workflowCreatedBy: 'status_created_by',
    fields: [
      {
        name: 'fact',
        label: 'Fact pattern',
        placeholder: 'Short context',
        type: 'editor',
        editor: {
          buttons: {
            bold: true,
            italics: true,
            h3: true,
            underline: true,
            ul: true,
            ol: true,
            quote: true,
            undo: true,
            redo: true
          }
        }
      },
      {
        name: 'question',
        label: 'Question',
        placeholder: 'Question',
        type: 'textarea',
        rows: 2,
        validation: {
          method: function (value) {
            if (value) {
              return value.length > 5
            } else {
              return false
            }
          },
          text: 'Needs more than 5 characters.'
        }
      },
      {
        name: 'answer',
        label: 'Short answer',
        placeholder: 'Short answer',
        type: 'textarea',
        rows: 2,
        validation: {
          method: function (value) {
            if (value) {
              return value.length > 1
            } else {
              return false
            }
          },
          text: 'Needs more than 1 character.'
        }
      },
      {
        name: 'long',
        label: 'Long answer',
        placeholder: 'Long answer',
        type: 'editor',
        editor: {
          buttons: {
            bold: true,
            italics: true,
            h3: true,
            underline: true,
            ul: true,
            ol: true,
            quote: true,
            undo: true,
            redo: true
          }
        }
      },
      {
        name: 'regulations',
        label: 'Regulatory references',
        placeholder: 'Regulatory references',
        type: 'textarea',
        rows: 3
      },
      {
        name: 'insights',
        label: 'Insights references',
        placeholder: 'Insights references',
        type: 'textarea',
        rows: 3
      },
      {
        name: 'opportunity',
        label: 'Opportunity',
        placeholder: 'Opportunity number',
        type: 'input'
      },
      {
        name: 'faqproject_id',
        label: 'Project',
        type: 'selectOneFromDatabaseOwn',
        optionsModel: 'faqproject'
      }
    ]
  },
  {
    id: 'faqproject',
    model: 'faqproject',
    name: 'FAQ Project',
    fields: [
      {
        name: 'name',
        label: 'Name',
        placeholder: 'Name',
        type: 'input'
      },
      {
        name: 'ugroup',
        label: 'Usergroup',
        type: 'selectList',
        config: {
          labelLinkingField: 'ugroup_id',
          labelModel: 'ugroup',
          linkingModel: 'faqprojectugroup',
          name: 'Usergroups',
          parentLinkingField: 'faqproject_id',
          relation: 'faqprojects'
        }
      }
    ]
  },
  {
    id: 'impactexpert',
    model: 'impactexpert',
    name: 'KPMG expert',
    routeName: 'Expert',
    fields: [
      {
        name: 'forename',
        label: 'Name',
        placeholder: 'Name',
        type: 'input'
      },
      {
        name: 'surname',
        label: 'Surname',
        placeholder: 'Surname',
        type: 'input'
      },
      {
        name: 'title',
        label: 'Grade',
        placeholder: 'Grade (e.g. Partner)',
        type: 'input'
      },
      {
        name: 'role',
        label: 'Role',
        placeholder: 'Role (e.g. Head of...)',
        type: 'input'
      },
      {
        name: 'email',
        label: 'Email',
        placeholder: 'exmple@email.com',
        type: 'input'
      },
      {
        name: 'phone',
        label: 'Phone',
        placeholder: 'provide country code + 00 000 000 000',
        type: 'input'
      },
      {
        name: 'country',
        label: 'Country',
        selected: null,
        type: 'select',
        options: countriesOptions
      }
      /*
      {
        name: 'initiative',
        label: 'Initiatives',
        type: 'selectList',
        config: {
          labelDelField: 'impactinitiatives.del',
          labelLinkingField: 'impactinitiative_id',
          labelModel: 'impactinitative',
          linkingModel: 'impactexpertimpactinitiative',
          name: 'Initiatives',
          parentLinkingField: 'impactexpert_id',
          relation: 'impactexperts'
        }
      }
      */
    ]
  },
  {
    id: 'impactinitiative',
    model: 'impactinitiative',
    name: 'Initiative',
    nameProperty: 'name',
    routeName: 'ImpactInitiative',
    createdBy: 'created_by',
    updatedAt: 'updated_at',
    updatedBy: 'updated_by',
    workflowCreatedAt: 'workflow_created_at',
    workflowCreatedBy: 'workflow_created_by',
    faqProjectPermission: true,
    log: {
      model: 'impactinitiativeslog',
      parent: 'impactinitiative_id',
      data: 'data'
    },
    fields: [
      {
        name: 'name',
        label: 'Name',
        placeholder: 'Name',
        type: 'input',
        validation: {
          method: function (value) {
            if (value) {
              return value.length > 5
            } else {
              return false
            }
          },
          text: 'Needs more than 5 characters'
        }
      },
      {
        name: 'headline',
        label: 'Headline',
        placeholder: 'Short headline',
        type: 'textarea',
        rows: 6,
        validation: {
          method: function (value) {
            if (value) {
              return value.length > 5
            } else {
              return false
            }
          },
          text: 'Needs more than 5 characters'
        }
      },
      {
        name: 'faqproject_id',
        label: 'Dataset',
        type: 'selectOneFromDatabaseOwn',
        optionsModel: 'faqproject',
        info: 'This will drive permissions to edit the record'
      },
      {
        name: 'description',
        label: 'Full details',
        placeholder: 'Full details...',
        type: 'editor',
        editor: {
          buttons: {
            bold: true,
            italics: true,
            h3: true,
            underline: true,
            ul: true,
            ol: true,
            quote: true,
            undo: true,
            redo: true
          }
        }
      },
      {
        name: 'report_type',
        label: 'Report type',
        placeholder: 'Report type',
        type: 'textarea',
        rows: 3
      },
      {
        name: 'status_id',
        label: 'Implementation status',
        selected: 1,
        type: 'selectOneFromDatabase',
        optionsModel: 'impactstatus'
      },
      {
        name: 'country',
        label: 'Country',
        selected: null,
        type: 'select',
        options: countriesOptions,
        validation: {
          method: function (value) {
            if (value) {
              return true
            } else {
              return false
            }
          },
          text: 'A country needs to be selected'
        }
      },
      {
        name: 'issued',
        label: 'Date issued',
        type: 'date'
      },
      {
        name: 'effective',
        label: 'Date effective',
        type: 'date'
      },
      {
        name: 'first_reporting',
        label: 'First reporting date',
        type: 'date'
      },
      {
        name: 'mandatory',
        label: 'Mandatory reporting',
        type: 'checkbox'
      },
      {
        name: 'optional',
        label: 'Optional reporting',
        type: 'checkbox'
      },
      {
        name: 'comply_explain',
        label: 'Comply or explain',
        type: 'checkbox'
      },
      {
        name: 'assurance_id',
        label: 'Assurance required',
        selected: 1,
        type: 'selectOneFromDatabase',
        optionsModel: 'assurance'
      },
      {
        name: 'reportingfrequency_id',
        label: 'Reporting frequency',
        selected: 1,
        type: 'selectOneFromDatabase',
        optionsModel: 'reportingfrequency'
      },
      {
        name: 'impactpolicy_id',
        label: 'Impact policy',
        type: 'selectOneFromDatabaseOrNull',
        optionsModel: 'impactpolicy'
      },
      {
        name: 'policydata',
        label: 'Policy data',
        type: 'jsonb',
        setDefinition: 'impactpolicy_id',
        sets: policysets
      },
      {
        name: 'tax_coverage',
        label: 'Tax coverage',
        placeholder: 'Products or services upon which tax is leived',
        type: 'textarea',
        rows: 3
      },
      {
        name: 'tax_base',
        label: 'Tax base',
        placeholder: 'Tax base e.g. per kilogram',
        type: 'textarea',
        rows: 3
      },
      {
        name: 'tax_local_rate',
        label: 'Tax rate %',
        placeholder: '6.23',
        type: 'number'
      },
      {
        name: 'tax_local_rate_currency',
        label: 'Tax rate local currency',
        selected: null,
        type: 'select',
        options: currencyOptions
      },
      {
        name: 'tax_normalised_rate',
        label: 'Tax normalised rate (USD)',
        placeholder: '3.15',
        type: 'number'
      },
      {
        name: 'tax_scheduled_increases',
        label: 'Tax scheduled increases',
        type: 'textarea',
        rows: 6
      },
      {
        name: 'tax_penalty',
        label: 'Tax penalty for non-compliance',
        type: 'textarea',
        rows: 6
      },
      {
        name: 'tax_exemptions',
        label: 'Tax exemptions available',
        type: 'textarea',
        rows: 6
      },
      {
        name: 'tax_incentive_rate',
        label: 'Tax incentive rate %',
        placeholder: '8.21',
        type: 'number'
      },
      {
        name: 'tax_incentive_currency',
        label: 'Tax incentive rate local currency',
        placeholder: '8.21',
        type: 'number'
      },
      {
        name: 'tax_incentive_normalised',
        label: 'Tax incentive normalised rate (USD)',
        placeholder: '8.21',
        type: 'number'
      },
      {
        name: 'tax_conditions_for_relief',
        label: 'Tax conditions for relief',
        placeholder: 'Conditions that must be met to receive the incentive',
        type: 'textarea',
        rows: 6
      },
      {
        name: 'tax_incentive_time',
        label: 'Tax incentive time restrictions',
        placeholder: 'Any restrictions on the time period for which the incentive is available',
        type: 'textarea',
        rows: 3
      },
      {
        name: 'secotrs',
        label: 'sectors',
        type: 'selectSectors',
        config: {
          parentLinkingField: 'initiative_id',
          parentModel: 'impactinitiative',
          sectorModel: 'impactinitiativesector'
        },
        info: 'You can select multiple sectors. We use the Global Industry Classification Standard (GICS) to classify sectors: https://en.wikipedia.org/wiki/Global_Industry_Classification_Standard'
      },
      {
        name: 'regulators',
        label: 'regulators',
        type: 'selectList',
        config: {
          labelLinkingField: 'regulator_id',
          labelModel: 'regulator',
          linkingModel: 'impactinitiativeregulator',
          name: 'Regulators',
          parentLinkingField: 'impactinitiative_id',
          relation: 'impactinitiatives'
        }
      },
      {
        name: 'expert',
        label: 'Experts',
        type: 'expertLookup',
        config: {
          labelLinkingField: 'impactexpert_id',
          labelModel: 'impactexpert',
          linkingModel: 'impactexpertimpactinitiative',
          linkable: true,
          linkRouteName: 'ImpactExpert',
          name: 'Experts',
          parentLinkingField: 'impactinitiative_id',
          relation: 'impactinitiatives'
        }
      },
      {
        name: 'topic',
        label: 'Topics',
        type: 'selectList',
        config: {
          labelLinkingField: 'impacttopic_id',
          labelModel: 'impacttopic',
          linkingModel: 'impactinitiativeimpacttopic',
          name: 'Topics',
          parentLinkingField: 'impactinitiative_id',
          relation: 'impactinitiatives'
        }
      },
      {
        name: 'taxonomy-mechanism',
        label: 'taxonomy-mechanism',
        type: 'selectListTaxonomyChildren',
        config: {
          labelLinkingField: 'taxonomy_id',
          labelModel: 'taxonomy',
          linkingModel: 'impactinitiativetaxonomy',
          name: 'Mechanism',
          parentLinkingField: 'impactinitiative_id',
          relation: 'impactinitiatives',
          taxonomyParentId: 1
        }
      },
      {
        name: 'taxonomy-policy',
        label: 'taxonomy-policy',
        type: 'selectListTaxonomyChildren',
        config: {
          labelLinkingField: 'taxonomy_id',
          labelModel: 'taxonomy',
          linkingModel: 'impactinitiativetaxonomy',
          name: 'Policy area',
          parentLinkingField: 'impactinitiative_id',
          relation: 'impactinitiatives',
          taxonomyParentId: 6
        }
      },
      {
        name: 'taxonomy-incentive-direction',
        label: 'taxonomy-incentive-direction',
        type: 'selectListTaxonomyChildren',
        config: {
          labelLinkingField: 'taxonomy_id',
          labelModel: 'taxonomy',
          linkingModel: 'impactinitiativetaxonomy',
          name: 'Incentive direction',
          parentLinkingField: 'impactinitiative_id',
          relation: 'impactinitiatives',
          taxonomyParentId: 11
        }
      },
      {
        name: 'taxonomy-esg',
        label: 'taxonomy-esg',
        type: 'selectListTaxonomyChildren',
        config: {
          labelLinkingField: 'taxonomy_id',
          labelModel: 'taxonomy',
          linkingModel: 'impactinitiativetaxonomy',
          name: 'ESG components',
          parentLinkingField: 'impactinitiative_id',
          relation: 'impactinitiatives',
          taxonomyParentId: 15
        }
      },
      {
        name: 'taxonomy-tax-type',
        label: 'taxonomy-tax-type',
        type: 'selectListTaxonomyChildren',
        config: {
          labelLinkingField: 'taxonomy_id',
          labelModel: 'taxonomy',
          linkingModel: 'impactinitiativetaxonomy',
          name: 'Tax type',
          parentLinkingField: 'impactinitiative_id',
          relation: 'impactinitiatives',
          taxonomyParentId: 19
        }
      },
      {
        name: 'taxonomy-environmental-tax-type',
        label: 'taxonomy-environmental-tax-type',
        type: 'selectListTaxonomyChildren',
        config: {
          labelLinkingField: 'taxonomy_id',
          labelModel: 'taxonomy',
          linkingModel: 'impactinitiativetaxonomy',
          name: 'Environmental tax type',
          parentLinkingField: 'impactinitiative_id',
          relation: 'impactinitiatives',
          taxonomyParentId: 33
        }
      },
      {
        name: 'taxonomy-rate-charging-mechanism',
        label: 'taxonomy-rate-charging-mechanism',
        type: 'selectListTaxonomyChildren',
        config: {
          labelLinkingField: 'taxonomy_id',
          labelModel: 'taxonomy',
          linkingModel: 'impactinitiativetaxonomy',
          name: 'Tax rate charging mechanism',
          parentLinkingField: 'impactinitiative_id',
          relation: 'impactinitiatives',
          taxonomyParentId: 38
        }
      },
      {
        name: 'taxonomy-tax-incentive-type',
        label: 'taxonomy-tax-incentive-type',
        type: 'selectListTaxonomyChildren',
        config: {
          labelLinkingField: 'taxonomy_id',
          labelModel: 'taxonomy',
          linkingModel: 'impactinitiativetaxonomy',
          name: 'Tax incentive types',
          parentLinkingField: 'impactinitiative_id',
          relation: 'impactinitiatives',
          taxonomyParentId: 43
        }
      },
      {
        name: 'taxonomy-tax-monetisation',
        label: 'taxonomy-tax-monetisation',
        type: 'selectListTaxonomyChildren',
        config: {
          labelLinkingField: 'taxonomy_id',
          labelModel: 'taxonomy',
          linkingModel: 'impactinitiativetaxonomy',
          name: 'Monetisation',
          parentLinkingField: 'impactinitiative_id',
          relation: 'impactinitiatives',
          taxonomyParentId: 49
        }
      },
      {
        name: 'reportingframework',
        label: 'Reporting framework',
        type: 'selectList',
        config: {
          labelLinkingField: 'reportingframework_id',
          labelModel: 'reportingframework',
          linkingModel: 'impactinitiativereportingframework',
          name: 'Reporting frameworks',
          parentLinkingField: 'impactinitiative_id',
          relation: 'impactinitiatives'
        }
      },
      {
        name: 'aitag',
        label: 'AI Tags',
        type: 'selectList',
        config: {
          labelLinkingField: 'aitag_id',
          labelModel: 'aitag',
          linkingModel: 'aitagimpactinitiative',
          name: 'AI Tags',
          parentLinkingField: 'impactinitiative_id',
          relation: 'impactinitiatives'
        }
      },
      {
        name: 'regtopic',
        label: 'Regtopics',
        type: 'selectList',
        config: {
          labelLinkingField: 'regtopic_id',
          labelModel: 'regtopic',
          linkingModel: 'impactinitiativeregtopic',
          name: 'Regtopics',
          parentLinkingField: 'impactinitiative_id',
          relation: 'impactinitiatives'
        }
      },
      {
        name: 'entity',
        label: 'Entities',
        type: 'selectList',
        config: {
          labelLinkingField: 'entity_id',
          labelModel: 'entity',
          linkingModel: 'entityimpactinitiative',
          name: 'Entities',
          parentLinkingField: 'impactinitiative_id',
          relation: 'impactinitiatives'
        }
      },
      {
        name: 'regulation',
        label: 'Regulations',
        type: 'selectListSlim',
        config: {
          labelLinkingField: 'regulation_id',
          labelModel: 'regulation',
          linkingModel: 'impactinitiativeregulation',
          name: 'Regulations',
          nameProperty: 'name_short',
          parentLinkingField: 'impactinitiative_id',
          relation: 'impactinitiatives'
        }
      },
      {
        name: 'event',
        label: 'Event',
        type: 'idLookup',
        config: {
          labelLinkingField: 'event_id',
          labelModel: 'event',
          linkingModel: 'eventimpactinitiative',
          name: 'Events',
          parentLinkingField: 'impactinitiative_id',
          relation: 'impactinitiatives'
        }
      },
      {
        name: 'article',
        label: 'Insight',
        type: 'idLookup',
        config: {
          labelLinkingField: 'article_id',
          labelModel: 'article',
          linkingModel: 'articleimpactinitiative',
          name: 'Insights',
          nameProperty: 'title',
          parentLinkingField: 'impactinitiative_id',
          relation: 'impactinitiatives'
        }
      },
      {
        name: 'publication',
        label: 'News',
        type: 'idLookup',
        config: {
          labelLinkingField: 'publication_id',
          labelModel: 'publication',
          linkingModel: 'impactinitiativepublication',
          name: 'News',
          parentLinkingField: 'impactinitiative_id',
          relation: 'impactinitiatives'
        }
      },
      {
        name: 'control',
        label: 'Controls',
        type: 'nameLookup',
        config: {
          labelLinkingField: 'control_id',
          labelModel: 'control',
          linkingModel: 'controlimpactinitiative',
          linkable: true,
          linkRouteName: 'Control',
          name: 'Controls',
          nameProperty: 'name',
          parentLinkingField: 'impactinitiative_id',
          relation: 'impactinitiatives',
          searchColumn: 'name'
        }
      },
      {
        name: 'linkss',
        label: 'Links',
        type: 'links',
        config: {
          model: 'impactinitiativeslink',
          property: 'impactinitiative_id'
        }
      },
      {
        name: 'reviewers_notes',
        label: 'Reviewers notes and comments',
        placeholder: 'Comments for reviewers and editors',
        type: 'textarea',
        rows: 10
      }
    ]
  },
  {
    id: 'impactpolicy',
    model: 'impactpolicy',
    name: 'Impact policy',
    routeName: 'ImpactPolicy',
    fields: [
      {
        name: 'name',
        label: 'Policy name',
        placeholder: 'policy name',
        type: 'input'
      },
      {
        name: 'summary',
        label: 'Summary',
        placeholder: 'Brief summary',
        type: 'textarea',
        rows: 12
      },
      {
        name: 'cpd',
        label: 'Include in CPD',
        selected: true,
        type: 'select',
        options: booleanOptions
      },
      {
        name: 'regtopic',
        label: 'Regulatory topics (news)',
        type: 'selectList',
        config: {
          labelLinkingField: 'regtopic_id',
          labelModel: 'regtopic',
          linkingModel: 'impactpolicyregtopic',
          name: 'Regulatory topics (news)',
          parentLinkingField: 'impactpolicy_id',
          relation: 'impactpolicies'
        }
      },
      {
        name: 'taxonomy-policy',
        label: 'taxonomy-policy',
        type: 'selectListTaxonomyChildren',
        config: {
          labelLinkingField: 'taxonomy_id',
          labelModel: 'taxonomy',
          linkingModel: 'impactpolicytaxonomy',
          name: 'Policy area',
          parentLinkingField: 'impactpolicy_id',
          relation: 'impactpolicies',
          taxonomyParentId: 6
        }
      },
      {
        name: 'topic',
        label: 'Topics',
        type: 'selectList',
        config: {
          labelLinkingField: 'impacttopic_id',
          labelModel: 'impacttopic',
          linkingModel: 'impactpolicyimpacttopic',
          name: 'Topics',
          parentLinkingField: 'impactpolicy_id',
          relation: 'policies'
        }
      }
    ]
  },
  {
    id: 'impactstatus',
    model: 'impactstatus',
    name: 'Initiative status',
    routeName: 'Impactstatus',
    fields: [
      {
        name: 'name',
        label: 'Name',
        placeholder: 'Name',
        type: 'input'
      }
    ]
  },
  {
    id: 'impacttopic',
    model: 'impacttopic',
    name: 'Impact topic',
    routeName: 'ImpactTopics',
    fields: [
      {
        name: 'name',
        label: 'Name',
        placeholder: 'Name',
        type: 'input'
      },
      {
        name: 'aitag',
        label: 'AI Tags',
        type: 'selectList',
        config: {
          labelLinkingField: 'aitag_id',
          labelModel: 'aitag',
          linkingModel: 'aitagimpacttopic',
          name: 'AI Tags',
          parentLinkingField: 'impacttopic_id',
          relation: 'impacttopics'
        }
      },
      {
        name: 'regtopic',
        label: 'Regtopics',
        type: 'selectList',
        config: {
          labelLinkingField: 'regtopic_id',
          labelModel: 'regtopic',
          linkingModel: 'impacttopicregtopic',
          name: 'Regtopics',
          parentLinkingField: 'impacttopic_id',
          relation: 'impacttopics'
        }
      }
    ]
  },
  {
    id: 'impactobligation',
    model: 'obligation',
    permission: 'impactobligation',
    name: 'Obligation',
    nameProperty: 'name',
    routeName: 'ImpactObligation',
    createdBy: 'created_by',
    updatedAt: 'updated_at',
    updatedBy: 'updated_by',
    workflowCreatedAt: 'status_created_at',
    workflowCreatedBy: 'status_created_by',
    fields: [
      {
        name: 'type_id',
        label: 'Type',
        selected: 1,
        type: 'selectOneFromDatabase',
        optionsModel: 'obligationtype'
      },
      {
        name: 'name',
        label: 'Name',
        placeholder: 'Name',
        type: 'input'
      },
      {
        name: 'description',
        label: 'Description',
        placeholder: 'Description',
        type: 'textarea',
        rows: 12
      },
      {
        name: 'context',
        label: 'Context / Entity scope',
        placeholder: 'Context and entity scope',
        type: 'textarea',
        rows: 12
      },
      {
        name: 'dates',
        label: 'Dates',
        type: 'dates',
        config: {
          model: 'obligationdate',
          property: 'obligation_id'
        }
      }
    ]
  },
  {
    id: 'person',
    model: 'person',
    name: 'Person',
    routeName: 'Person',
    fields: [
      {
        name: 'name',
        label: 'Name',
        placeholder: 'Name',
        type: 'input'
      },
      {
        name: 'type_id',
        label: 'Person type',
        type: 'selectOneFromDatabase',
        optionsModel: 'persontype'
      }
    ]
  },
  {
    id: 'persontype',
    model: 'persontype',
    name: 'Person type',
    routeName: 'Persontypes',
    fields: [
      {
        name: 'name',
        label: 'Name',
        placeholder: 'Name',
        type: 'input'
      }
    ]
  },
  {
    id: 'process',
    model: 'process',
    name: 'Process',
    nameProperty: 'name',
    routeName: 'Process',
    fields: [
      {
        name: 'number',
        label: 'Number',
        placeholder: '1',
        type: 'number'
      },
      {
        name: 'name',
        label: 'Name',
        placeholder: 'Name',
        type: 'input'
      },
      {
        name: 'parent_id',
        label: 'Parent process',
        type: 'selectOneFromDatabaseOrNull',
        optionsModel: 'process'
      },
      {
        name: 'description',
        label: 'Process description',
        placeholder: 'Full details...',
        type: 'editor',
        editor: {
          buttons: {
            bold: true,
            italics: true,
            h3: true,
            underline: true,
            ul: true,
            ol: true,
            quote: true,
            undo: true,
            redo: true
          }
        }
      }
    ]
  },
  {
    id: 'producttype',
    model: 'producttype',
    name: 'Producttype',
    routeName: 'Producttypes',
    fields: [
      {
        name: 'name',
        label: 'Name',
        placeholder: 'Name',
        type: 'input'
      }
    ]
  },
  {
    id: 'publication',
    model: 'publication',
    name: 'News items',
    routeName: 'NewsItem',
    fields: [
      {
        name: 'name',
        label: 'Headline',
        placeholder: 'Headline',
        type: 'textarea',
        rows: 2,
        validation: {
          method: function (value) {
            if (value) {
              return value.length > 5
            } else {
              return false
            }
          },
          text: 'Needs more than 5 characters'
        }
      },
      {
        name: 'nameLocal',
        label: 'Headline Local Language',
        placeholder: 'Headline',
        type: 'textarea',
        rows: 2,
        validation: {
          method: function (value) {
            if (value) {
              return value.length > 5
            } else {
              return false
            }
          },
          text: 'Needs more than 5 characters'
        }
      },
      {
        name: 'body',
        label: 'Introduction',
        placeholder: 'Introcution',
        type: 'textarea',
        rows: 4
      },
      {
        name: 'bodyLocal',
        label: 'Introduction Local Language',
        placeholder: 'Introcution',
        type: 'textarea',
        rows: 4
      },
      {
        name: 'link',
        label: 'Link',
        placeholder: 'wwww.example.com',
        type: 'input',
        validation: {
          method: function (value) {
            if (value) {
              return value.length > 5
            } else {
              return false
            }
          },
          text: 'Needs more than 5 characters'
        }
      },
      {
        name: 'langCode',
        label: 'Language Code',
        placeholder: 'en',
        type: 'input'
      },
      {
        name: 'langName',
        label: 'Language Name',
        placeholder: 'English',
        type: 'input'
      },
      {
        name: 'countryCode',
        label: 'Country Code',
        placeholder: 'GB',
        type: 'input'
      },
      {
        name: 'countryName',
        label: 'Country Name',
        placeholder: 'United Kingdom',
        type: 'input'
      },
      {
        name: 'date',
        label: 'Date',
        placeholder: '',
        type: 'input',
        validation: {
          method: function (value) {
            if (value) {
              return value.length > 5
            } else {
              return false
            }
          },
          text: 'Needs more than 5 characters'
        }
      },
      {
        name: 'dateTime',
        label: 'DateTime',
        placeholder: '',
        type: 'input',
        validation: {
          method: function (value) {
            if (value) {
              return value.length > 5
            } else {
              return false
            }
          },
          text: 'Needs more than 5 characters'
        }
      },
      {
        name: 'publisher',
        label: 'Pubilsher',
        placeholder: 'FCA',
        type: 'input',
        validation: {
          method: function (value) {
            if (value) {
              return value.length > 1
            } else {
              return false
            }
          },
          text: 'Needs more than 1 characters'
        }
      },
      {
        name: 'source',
        label: 'Source',
        placeholder: 'FCA news',
        type: 'input',
        validation: {
          method: function (value) {
            if (value) {
              return value.length > 5
            } else {
              return false
            }
          },
          text: 'Needs more than 5 characters'
        }
      },
      {
        name: 'type',
        label: 'Type',
        placeholder: 'news',
        type: 'input',
        validation: {
          method: function (value) {
            if (value) {
              return value.length > 2
            } else {
              return false
            }
          },
          text: 'Needs more than 2 characters'
        }
      }
    ]
  },
  {
    id: 'regtopicgroup',
    model: 'regtopicgroup',
    name: 'Topic Group',
    routeName: 'Regtopicgroups',
    fields: [
      {
        name: 'name',
        label: 'Name',
        placeholder: 'Financial services',
        type: 'input'
      },
      {
        name: 'description',
        label: 'Description',
        placeholder: 'All regulatory topics related to financial services sector',
        type: 'input'
      },
      {
        name: 'del',
        label: 'Deleted',
        selected: false,
        type: 'select',
        options: booleanOptions
      },
      {
        name: 'regtopic',
        label: 'Regtopics',
        type: 'selectList',
        config: {
          labelDelField: 'regtopics.del',
          labelLinkingField: 'regtopic_id',
          labelModel: 'regtopic',
          linkingModel: 'regtopicregtopicgroup',
          name: 'Regtopics',
          parentLinkingField: 'regtopicgroup_id',
          relation: 'regtopicgroups'
        }
      }
    ]
  },
  {
    id: 'regulation',
    model: 'regulation',
    name: 'Regulation',
    routeName: 'RegDecompRegulation',
    fields: [
      {
        name: 'name_short',
        label: 'Short name',
        placeholder: 'Short name',
        type: 'input'
      },
      {
        name: 'name_long',
        label: 'Long name',
        placeholder: 'Long name',
        type: 'input'
      },
      {
        name: 'code',
        label: 'Code',
        placeholder: 'code',
        type: 'input'
      },
      {
        name: 'url',
        label: 'URL',
        placeholder: 'www.example.com',
        type: 'input'
      },
      {
        name: 'uuid',
        label: 'UUID',
        placeholder: '9djsuwe83',
        type: 'input'
      },
      {
        name: 'country_code',
        label: 'Country',
        selected: null,
        type: 'select',
        options: countriesCodesOptions
      },
      {
        name: 'regulator_id',
        label: 'Regulator',
        type: 'selectOneFromDatabase',
        optionsModel: 'regulator'
      },
      {
        name: 'type_id',
        label: 'Type',
        type: 'selectOneFromDatabase',
        optionsModel: 'regulationtype'
      },
      {
        name: 'status_id',
        label: 'Status',
        type: 'selectOneFromDatabase',
        optionsModel: 'regulationstatus'
      },
      {
        name: 'date_in_force',
        label: 'Date in force',
        type: 'date'
      },
      {
        name: 'date_last_amended',
        label: 'Date last amended',
        type: 'date'
      },
      {
        name: 'relationships',
        label: 'Relationships',
        type: 'relationships',
        config: {
          graph: 'regulations'
        }
      },
      {
        name: 'pdf',
        label: 'PDF',
        selected: false,
        type: 'select',
        options: booleanOptions
      },
      {
        name: 'static',
        label: 'S3 HTML',
        selected: false,
        type: 'select',
        options: booleanOptions
      },
      {
        name: 'body',
        label: 'Body',
        placeholder: '<html></html>',
        type: 'textarea',
        rows: 30
      }
    ]
  },
  {
    id: 'regulator',
    model: 'regulator',
    name: 'Regulator',
    nameProperty: 'name',
    routeName: 'Regulator2',
    createdBy: 'username',
    updatedAt: 'updated_at',
    fields: [
      {
        name: 'name',
        label: 'Name',
        placeholder: 'Name',
        type: 'input'
      },
      {
        name: 'comments',
        label: 'Long name',
        placeholder: 'Long name',
        type: 'input'
      },
      {
        name: 'del',
        label: 'Deleted',
        selected: false,
        type: 'select',
        options: deleteOptions
      }
    ]
  },
  {
    id: 'relationship',
    model: 'relationship',
    name: 'Relationship',
    routeName: 'Relationships',
    fields: [
      {
        name: 'name',
        label: 'Name',
        placeholder: 'supersedes',
        type: 'input'
      },
      {
        name: 'passive',
        label: 'Passive',
        placeholder: 'is superseded by',
        type: 'input'
      },
      {
        name: 'description',
        label: 'Description',
        placeholder: 'Explain relationship',
        type: 'input'
      },
      {
        name: 'del',
        label: 'Directional',
        selected: true,
        type: 'select',
        options: booleanOptions
      }
    ]
  },
  {
    id: 'reportingframework',
    model: 'reportingframework',
    name: 'Reporting framework',
    routeName: 'Reportingframework',
    fields: [
      {
        name: 'name',
        label: 'name',
        placeholder: 'TCFD',
        type: 'input'
      }
    ]
  },
  {
    id: 'reportingfrequency',
    model: 'reportingfrequency',
    name: 'Reporting frequency',
    routeName: 'Reportingfrequency',
    fields: [
      {
        name: 'name',
        label: 'reporting frequency',
        placeholder: 'annual / monthly',
        type: 'input'
      }
    ]
  },
  {
    id: 'riclist',
    model: 'riclist',
    name: 'RIC List',
    routeName: 'Riclists',
    fields: [
      {
        name: 'name',
        label: 'Name',
        placeholder: 'Name',
        type: 'input'
      },
      {
        name: 'comments',
        label: 'Comments',
        placeholder: 'Comments...',
        type: 'textarea',
        rows: 6
      }
    ]
  },
  {
    id: 'riskcomponent',
    model: 'riskcomponent',
    name: 'Risk components',
    nameProperty: 'name',
    routeName: 'Riskcomponent',
    fields: [
      {
        name: 'name',
        label: 'Name',
        placeholder: 'Name',
        type: 'input',
        validation: {
          method: function (value) {
            if (value) {
              return value.length > 3
            } else {
              return false
            }
          },
          text: 'Needs more than 3 characters'
        }
      },
      {
        name: 'description',
        label: 'Description',
        placeholder: 'Risk component description',
        type: 'editor',
        editor: {
          buttons: {
            bold: true,
            italics: true,
            h3: true,
            underline: true,
            ul: true,
            ol: true,
            quote: true,
            undo: true,
            redo: true
          }
        }
      },
      {
        name: 'del',
        label: 'Deleted',
        selected: false,
        type: 'select',
        options: deleteOptions
      }
    ]
  },
  {
    id: 'sanction',
    model: 'sanction',
    name: 'Sanction',
    routeName: 'Sanction',
    createdBy: 'created_by',
    updatedAt: 'updated_at',
    updatedBy: 'updated_by',
    workflowCreatedAt: 'workflow_created_at',
    workflowCreatedBy: 'workflow_created_by',
    fields: [
      {
        name: 'name',
        label: 'Headline / title',
        placeholder: 'Headline / title',
        type: 'input'
      },
      {
        name: 'urn',
        label: 'Identifier / document id',
        placeholder: 'EO 123',
        type: 'input'
      },
      {
        name: 'link',
        label: 'URL link',
        placeholder: 'https://example.com',
        type: 'input'
      },
      {
        name: 'us',
        label: 'Linked to US?',
        selected: false,
        type: 'select',
        options: booleanOptions
      },
      {
        name: 'third',
        label: 'Third parties?',
        selected: false,
        type: 'select',
        options: booleanOptions
      },
      {
        name: 'start',
        label: 'Start',
        type: 'date'
      },
      {
        name: 'end',
        label: 'End',
        type: 'date'
      },
      {
        name: 'body',
        label: 'Full details',
        placeholder: 'Full details...',
        type: 'editor',
        editor: {
          buttons: {
            bold: true,
            italics: true,
            h2: true,
            h3: true,
            link: true,
            underline: true,
            ul: true,
            ol: true,
            quote: true,
            undo: true,
            redo: true
          }
        }
      },
      {
        name: 'keywords',
        label: 'Keywords for search',
        placeholder: 'plain text to include in search, will be hidden from users',
        type: 'textarea',
        rows: 30
      },
      {
        name: 'countries',
        label: 'countries',
        type: 'selectList',
        config: {
          labelLinkingField: 'country_id',
          labelModel: 'country',
          linkingModel: 'countrysanction',
          name: 'Countries',
          parentLinkingField: 'sanction_id',
          relation: 'sanctions'
        }
      },
      {
        name: 'regulators',
        label: 'regulators',
        type: 'selectList',
        config: {
          labelLinkingField: 'regulator_id',
          labelModel: 'regulator',
          linkingModel: 'regulatorsanction',
          name: 'Regulators',
          parentLinkingField: 'sanction_id',
          relation: 'sanctions'
        }
      },
      {
        name: 'channels',
        label: 'channels',
        type: 'selectList',
        config: {
          labelLinkingField: 'schannel_id',
          labelModel: 'schannel',
          linkingModel: 'sanctionschannel',
          name: 'Channels',
          parentLinkingField: 'sanction_id',
          relation: 'sanctions'
        }
      },
      {
        name: 'customers',
        label: 'customers',
        type: 'selectList',
        config: {
          labelLinkingField: 'scustomer_id',
          labelModel: 'scustomer',
          linkingModel: 'sanctionscustomer',
          name: 'Customers',
          parentLinkingField: 'sanction_id',
          relation: 'sanctions'
        }
      },
      {
        name: 'parties',
        label: 'parties',
        type: 'selectList',
        config: {
          labelLinkingField: 'sparty_id',
          labelModel: 'sparty',
          linkingModel: 'sanctionsparty',
          name: 'Parties',
          parentLinkingField: 'sanction_id',
          relation: 'sanctions'
        }
      },
      {
        name: 'products',
        label: 'products',
        type: 'selectList',
        config: {
          labelLinkingField: 'sproduct_id',
          labelModel: 'sproduct',
          linkingModel: 'sanctionsproduct',
          name: 'Products',
          parentLinkingField: 'sanction_id',
          relation: 'sanctions'
        }
      },
      {
        name: 'sectors',
        label: 'sectors',
        type: 'selectList',
        config: {
          labelLinkingField: 'ssector_id',
          labelModel: 'ssector',
          linkingModel: 'sanctionssector',
          name: 'Sectors',
          parentLinkingField: 'sanction_id',
          relation: 'sanctions'
        }
      },
      {
        name: 'types',
        label: 'types',
        type: 'selectList',
        config: {
          labelLinkingField: 'stype_id',
          labelModel: 'stype',
          linkingModel: 'sanctionstype',
          name: 'Types',
          parentLinkingField: 'sanction_id',
          relation: 'sanctions'
        }
      }
    ]
  },
  {
    id: 'schannel',
    model: 'schannel',
    name: 'Channel',
    route: {
      name: 'ListSimple',
      params: {
        model: 'schannel'
      }
    },
    fields: [
      {
        name: 'name',
        label: 'Name',
        placeholder: 'Name',
        type: 'input'
      },
      {
        name: 'body',
        label: 'Details',
        placeholder: 'Details',
        type: 'textarea',
        rows: 3
      }
    ]
  },
  {
    id: 'scustomer',
    model: 'scustomer',
    name: 'Customer',
    route: {
      name: 'ListSimple',
      params: {
        model: 'scustomer'
      }
    },
    fields: [
      {
        name: 'name',
        label: 'Name',
        placeholder: 'Name',
        type: 'input'
      },
      {
        name: 'body',
        label: 'Details',
        placeholder: 'Details',
        type: 'textarea',
        rows: 3
      }
    ]
  },
  {
    id: 'sparty',
    model: 'sparty',
    name: 'Party',
    route: {
      name: 'ListSimple',
      params: {
        model: 'sparty'
      }
    },
    fields: [
      {
        name: 'name',
        label: 'Name',
        placeholder: 'Name',
        type: 'input'
      },
      {
        name: 'body',
        label: 'Details',
        placeholder: 'Details',
        type: 'textarea',
        rows: 3
      }
    ]
  },
  {
    id: 'sproduct',
    model: 'sproduct',
    name: 'Product',
    route: {
      name: 'ListSimple',
      params: {
        model: 'sproduct'
      }
    },
    fields: [
      {
        name: 'name',
        label: 'Name',
        placeholder: 'Name',
        type: 'input'
      },
      {
        name: 'body',
        label: 'Details',
        placeholder: 'Details',
        type: 'textarea',
        rows: 3
      }
    ]
  },
  {
    id: 'ssector',
    model: 'ssector',
    name: 'Sector',
    route: {
      name: 'ListSimple',
      params: {
        model: 'ssector'
      }
    },
    fields: [
      {
        name: 'name',
        label: 'Name',
        placeholder: 'Name',
        type: 'input'
      },
      {
        name: 'body',
        label: 'Details',
        placeholder: 'Details',
        type: 'textarea',
        rows: 3
      }
    ]
  },
  {
    id: 'stype',
    model: 'stype',
    name: 'Type',
    route: {
      name: 'ListSimple',
      params: {
        model: 'stype'
      }
    },
    fields: [
      {
        name: 'name',
        label: 'Name',
        placeholder: 'Name',
        type: 'input'
      },
      {
        name: 'body',
        label: 'Details',
        placeholder: 'Details',
        type: 'textarea',
        rows: 3
      }
    ]
  },
  {
    id: 'source',
    model: 'source',
    name: 'Source',
    routeName: 'Source',
    fields: [
      {
        name: 'source',
        label: 'Source',
        placeholder: 'Source',
        type: 'input'
      },
      {
        name: 'publisher',
        label: 'Publisher',
        placeholder: 'Publisher',
        type: 'input'
      },
      {
        name: 'type',
        label: 'Type',
        placeholder: 'Type',
        type: 'input'
      },
      {
        name: 'url',
        label: 'Url',
        placeholder: 'https://example.com',
        type: 'input'
      },
      {
        name: 'parser',
        label: 'Parser',
        placeholder: 'rss, duster, hoover, proxy',
        type: 'input'
      },
      {
        name: 'autotag',
        label: 'Autotag',
        selected: true,
        type: 'select',
        options: booleanOptions
      }
    ]
  },
  {
    id: 'suggestrule',
    model: 'suggestrule',
    name: 'Tagging rule',
    nameProperty: 'name',
    routeName: 'Suggestrule',
    createdBy: 'created_by',
    updatedAt: 'updated_at',
    updatedBy: 'updated_by',
    fields: [
      {
        name: 'name',
        label: 'Name',
        placeholder: 'Name',
        type: 'input',
        validation: {
          method: function (value) {
            if (value) {
              return value.length > 2
            } else {
              return false
            }
          },
          text: 'Needs more than 2 characters'
        }
      },
      {
        name: 'active',
        label: 'Active',
        type: 'checkbox'
      },
      {
        name: 'regexTitle',
        label: 'regexTitle',
        placeholder: 'regexTitle;',
        type: 'textarea',
        rows: 3
      },
      {
        name: 'regexBody',
        label: 'regexBody',
        placeholder: 'regexBody;',
        type: 'textarea',
        rows: 3
      },
      {
        name: 'regexTitleExclude',
        label: 'regexTitleExclude',
        placeholder: 'regexTitleExclude;',
        type: 'textarea',
        rows: 3
      },
      {
        name: 'regexBodyExclude',
        label: 'regexBodyExclude',
        placeholder: 'regexBodyExclude;',
        type: 'textarea',
        rows: 3
      },
      {
        name: 'regtopic_id',
        label: 'Regtopic',
        type: 'selectOneFromDatabaseOrNull',
        optionsModel: 'regtopic',
        selected: null
      },
      {
        name: 'tag_id',
        label: 'Tag',
        type: 'selectOneFromDatabaseOrNull',
        optionsModel: 'tag',
        selected: null
      },
      {
        name: 'risk_id',
        label: 'Risk',
        type: 'selectOneFromDatabaseOrNull',
        optionsModel: 'risk',
        selected: null
      },
      {
        name: 'publishers',
        label: 'Sources include',
        type: 'publishers'
      },
      {
        name: 'publishersRemove',
        label: 'Sources exclude',
        type: 'publishers'
      }
    ]
  },
  {
    id: 'tag',
    model: 'label',
    name: 'Tag',
    nameProperty: 'name',
    routeName: 'Tag',
    fields: [
      {
        name: 'name',
        label: 'Name',
        placeholder: 'Name',
        type: 'input'
      },
      {
        name: 'description',
        label: 'Description',
        placeholder: 'Description',
        type: 'input'
      },
      {
        name: 'del',
        label: 'Deleted',
        selected: false,
        type: 'select',
        options: deleteOptions
      },
      {
        name: 'regtopic',
        label: 'Regtopics',
        type: 'selectList',
        config: {
          labelDelField: 'regtopics.del',
          labelLinkingField: 'regtopic_id',
          labelModel: 'regtopic',
          linkingModel: 'labelregtopic',
          name: 'Regtopics',
          parentLinkingField: 'label_id',
          relation: 'labels'
        }
      }
    ]
  },
  {
    id: 'taxonomy',
    model: 'taxonomy',
    name: 'Taxonomy',
    nameProperty: 'name',
    routeName: 'TaxonomyItem',
    createdBy: 'created_by',
    updatedBy: 'updated_by',
    fields: [
      {
        name: 'name',
        label: 'Name',
        placeholder: 'Name',
        type: 'input'
      },
      {
        name: 'description',
        label: 'Taxonomy description',
        placeholder: 'Full details...',
        type: 'editor',
        editor: {
          buttons: {
            bold: true,
            italics: true,
            h3: true,
            underline: true,
            ul: true,
            ol: true,
            quote: true,
            undo: true,
            redo: true
          }
        }
      },
      {
        name: 'parents',
        label: 'parents',
        type: 'selectList',
        config: {
          labelLinkingField: 'parent_id',
          labelModel: 'taxonomy',
          linkingModel: 'taxonomytaxonomy',
          name: 'Parents',
          parentLinkingField: 'child_id',
          relation: 'children'
        }
      },
      {
        name: 'children',
        label: 'children',
        type: 'selectList',
        config: {
          labelLinkingField: 'child_id',
          labelModel: 'taxonomy',
          linkingModel: 'taxonomytaxonomy',
          name: 'Children',
          parentLinkingField: 'parent_id',
          relation: 'parents'
        }
      }
    ]
  },
  {
    id: 'trainingcase',
    model: 'trainingcase',
    name: 'Training Case',
    nameProperty: 'name',
    routeName: 'Trainingcase',
    createdBy: 'created_by',
    updatedAt: 'updated_at',
    updatedBy: 'updated_by',
    workflowCreatedAt: 'workflow_created_at',
    workflowCreatedBy: 'workflow_created_by',
    faqProjectPermission: true,
    log: {
      model: 'trainingcaseslog',
      parent: 'trainingcase_id',
      data: 'data'
    },
    fields: [
      {
        name: 'name',
        label: 'Name',
        placeholder: 'Name',
        type: 'input',
        validation: {
          method: function (value) {
            if (value) {
              return value.length > 5
            } else {
              return false
            }
          },
          text: 'Needs more than 5 characters'
        }
      },
      {
        name: 'faqproject_id',
        label: 'Dataset',
        type: 'selectOneFromDatabaseOwn',
        optionsModel: 'faqproject'
      },
      {
        name: 'body',
        label: 'Details',
        placeholder: 'Full details...',
        type: 'editor',
        editor: {
          buttons: {
            bold: true,
            italics: true,
            h3: true,
            underline: true,
            ul: true,
            ol: true,
            quote: true,
            undo: true,
            redo: true
          }
        }
      },
      {
        name: 'controlsrisks',
        label: 'control risks',
        type: 'selectList',
        config: {
          labelLinkingField: 'controlrisk_id',
          labelModel: 'controlrisk',
          linkingModel: 'controlrisktrainingcase',
          name: 'Control Risks',
          parentLinkingField: 'trainingcase_id',
          relation: 'trainingcases'
        }
      },
      {
        name: 'risks',
        label: 'MAR risks',
        type: 'selectList',
        config: {
          labelLinkingField: 'risk_id',
          labelModel: 'risk',
          linkingModel: 'risktrainingcase',
          name: 'MAR Risks',
          parentLinkingField: 'trainingcase_id',
          relation: 'trainingcases'
        }
      },
      {
        name: 'publication',
        label: 'Publications',
        type: 'nameLookup',
        config: {
          labelLinkingField: 'publication_id',
          labelModel: 'publication',
          linkingModel: 'publicationtrainingcase',
          linkable: true,
          linkRouteName: 'NewsItem',
          name: 'News',
          nameProperty: 'name',
          parentLinkingField: 'trainingcase_id',
          relation: 'trainingcases',
          searchColumn: 'name'
        }
      },
      {
        name: 'control',
        label: 'Controls',
        type: 'nameLookup',
        config: {
          labelLinkingField: 'control_id',
          labelModel: 'control',
          linkingModel: 'controltrainingcase',
          linkable: true,
          linkRouteName: 'Control',
          name: 'Controls',
          nameProperty: 'name',
          parentLinkingField: 'trainingcase_id',
          relation: 'trainingcases',
          searchColumn: 'name'
        }
      },
      {
        name: 'obligation',
        label: 'Obligations',
        type: 'nameLookup',
        config: {
          labelLinkingField: 'obligation_id',
          labelModel: 'obligation',
          linkingModel: 'obligationtrainingcase',
          linkable: true,
          linkRouteName: 'RegDecompObligation',
          name: 'Obligations',
          nameProperty: 'name',
          parentLinkingField: 'trainingcase_id',
          relation: 'trainingcases',
          searchColumn: 'name'
        }
      }
    ]
  },
  {
    id: 'ugroup',
    model: 'ugroup',
    name: 'User group',
    fields: [
      {
        name: 'name',
        label: 'Name',
        placeholder: 'Name',
        type: 'input'
      },
      {
        name: 'faqproject',
        label: 'Faqproject',
        type: 'selectList',
        config: {
          labelLinkingField: 'faqproject_id',
          labelModel: 'faqproject',
          linkingModel: 'faqprojectugroup',
          name: 'FAQ Project',
          parentLinkingField: 'ugroup_id',
          relation: 'ugroups'
        }
      },
      {
        name: 'user',
        label: 'User',
        type: 'selectList',
        config: {
          labelLinkingField: 'user_id',
          labelModel: 'user',
          linkingModel: 'ugroupuser',
          name: 'Users',
          nameProperty: 'username',
          parentLinkingField: 'ugroup_id',
          relation: 'ugroups'
        }
      },
      {
        name: 'controlrisk',
        label: 'Control risk',
        type: 'selectList',
        config: {
          labelLinkingField: 'controlrisk_id',
          labelModel: 'controlrisk',
          linkingModel: 'controlriskugroup',
          name: 'Control risk',
          parentLinkingField: 'ugroup_id',
          relation: 'ugroups'
        }
      }
    ]
  }
]
