<template>
  <div>
    <div class="mb-2"><strong class="mr-2">{{name}}</strong>
      <b-button class="mr-2" v-if="editable" variant="outline-primary" size="sm" v-on:click="edit = true">edit</b-button>
    </div>
    <div class="mb-2">
      <span v-if="!loading">
        <div v-for="value in values" :key="value.id">
          <span v-if="linkable">
            <router-link :to="{ name: linkName, params: { id: value.id }}">
              {{value[nameProperty]}}
            </router-link>
          </span>
          <span v-if="!linkable">
            {{value[nameProperty]}}
          </span>
          <b-button v-if="edit || editAlwaysOn" variant="outline-primary" size="sm" v-on:click="deleteEdge(value)">remove</b-button>
        </div>
      </span>
      <span v-if="loading">
        <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
      </span>
    </div>
    <div v-if="edit || editAlwaysOn">
      <span v-if="!busy">
        <b-form-select class="mb-2" v-model="selected" :options="options" />
        <b-button v-if="validate" class="mr-2" variant="primary" size="sm" v-on:click="saveEdge">save</b-button>
        <b-button v-if="!editAlwaysOn" class="mr-2" variant="outline-primary" size="sm" v-on:click="edit = false">done</b-button>
      </span>
      <span v-if="busy"><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></span>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'SelectListTaxonomyChildren',
  props: {
    editable: {
      type: Boolean,
      default: true
    },
    editAlwaysOn: {
      type: Boolean,
      default: false
    },
    xxxlabelDelField: String,
    labelLinkingField: String,
    xxxlabelModel: String,
    linkingModel: String,
    linkable: {
      type: Boolean,
      default: false
    },
    linkName: {
      type: String,
      default: ''
    },
    name: String,
    nameProperty: {
      type: String,
      default: 'name'
    },
    own: {
      type: Boolean,
      default: false
    },
    parentId: Number,
    parentLinkingField: String,
    relation: String,
    taxonomyParentId: Number
  },
  /** props
  * vertexProp object { id: id, partition: partition}
  * directionProp string 'from vertex' or 'from list'
  * edgeLabelProp string - name of the edge
  * listSourceProp object { partition: partition, label: label}
  * */
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    },
    validate: function () {
      return this.selected
    }
  },
  created: function () {
    this.loadEdges()
  },
  data () {
    return {
      busy: false,
      edit: false,
      loading: true,
      options: [],
      selected: '',
      values: [],
      vertices: []
    }
  },
  methods: {
    buildOptions: async function () {
      this.$logger.debug('buildOptions start')
      this.busy = true
      try {
        // let response = await this.$http(config)
        this.vertices = await this.$Amplify.API.get('cosmos', `/taxonomy/parent/${this.taxonomyParentId}`)
        this.$logger.debug('got vertices', this.vertices)
        const optionsTemp = this.vertices.map(x => {
          return { value: x.id, text: x[this.nameProperty] }
        })
        if (this.values.length > 0) {
          this.values.forEach(x => {
            _.remove(optionsTemp, _.matchesProperty('value', x.id))
          })
        }
        this.options = _.concat([{ value: null, text: 'select' }], _.sortBy(optionsTemp, ['text']))
        this.selected = null
        this.busy = false
      } catch (e) {
        this.$logger.warn('getting vertices error error: ' + e)
      }
    },
    deleteEdge: async function (value) {
      this.$logger.debug('deletePropertyList started')
      this.busy = true
      try {
        // let response = await this.$http(config)
        let apiName = 'cosmos'
        let path = `/standard/relationship/${this.linkingModel}`
        let params = {
          body: {
            left: { column: this.parentLinkingField, id: this.parentId },
            right: { column: this.labelLinkingField, id: value.id }
          }
        }
        let response = await this.$Amplify.API.del(apiName, path, params)
        this.$stat.log({ action: 'unlinked property', model: this.linkingModel, payload: params.body })
        this.$logger.debug('deleted OK:', response)
        let temp = this.values
        _.pull(temp, value)
        this.values = temp
        this.buildOptions()
      } catch (e) {
        this.$logger.warn('delete error: ' + e)
      }
      this.busy = false
    },
    loadEdges: async function () {
      this.$logger.debug('loadEdges started')
      this.busy = true
      let path = `/standard/relation/taxonomy/${this.parentId}/${this.relation}/${this.parentLinkingField}/no-del/taxonomyParentId/${this.taxonomyParentId}`
      try {
        this.values = await this.$Amplify.API.get('cosmos', path)
        this.$logger.debug('load values', this.values)
        this.loading = false
      } catch (e) {
        this.$logger.warn('/topic/:id error', e)
      }
      this.buildOptions()
    },
    saveEdge: async function () {
      this.$logger.debug(`saveEdge start`)
      this.busy = true
      try {
        // let response = await this.$http(config)
        const apiName = 'cosmos'
        const path = `/standard/relationship/${this.linkingModel}`
        const params = {
          body: {
            left: { column: this.parentLinkingField, id: this.parentId },
            right: { column: this.labelLinkingField, id: this.selected },
            username: this.user.username
          }
        }
        const response = await this.$Amplify.API.put(apiName, path, params)
        this.$stat.log({ action: 'linked new property', model: this.linkingModel, model_id: response.id, payload: response })
        this.$logger.debug('saved OK:', response)
        const newValue = _.find(this.options, ['value', this.selected])
        const newRow = {}
        newRow.id = this.selected
        newRow[this.nameProperty] = newValue.text
        this.values.push(newRow)
        this.selected = null
        await this.buildOptions()
      } catch (e) {
        this.$logger.warn('event saving error: ' + e)
      }
      this.busy = false
    }
  }
}
</script>

<style>
</style>
