<template>
<div>
  <div v-if="loading">
    loading sources
    <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
  </div>
  <div v-if="!loading">
    <div class="mt-4"><strong>{{label}}</strong></div>
    <b-button variant="outline-primary" class="my-2" @click="visible = !visible">
      sources: {{publishers.length}} out of {{publishersOptions.length}}
    </b-button>
    <b-sidebar v-model="visible" :id="uuid" :title="label" backdrop backdrop-variant="transparent" shadow width="95%">
      <div class="px-3 py-2">
      <b-container>
        <b-row>
          <b-col>
            <b-form-checkbox-group
              :id="'check-box' + uuid"
              v-model="publishers"
              :options="publishersOptions"
              name="sources"
              style="column-count: 2;"
              stacked
            ></b-form-checkbox-group>
          </b-col>
          <b-col>
            <b-container>
              <b-row>
                <b-col>
                  <b-button variant="outline-primary" class="mb-4 mr-2" v-on:click="allPublishers">select all</b-button>
                  <b-button variant="outline-primary" class="mb-4 mr-2" v-on:click="nonePublishers">clear all</b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <h3>Quick add options</h3>
                  <div class="mb-4">Use buttons below to add groups of publishers to your selection</div>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <div>
                    <div><strong>Expert-led curation</strong></div>
                    <div><b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('level1')">RIC Level 1</b-button></div>
                    <div><b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('level2')">RIC Level 2</b-button></div>
                    <div><b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('accounting')">Accounting</b-button></div>
                    <div><b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('clients')">Clients</b-button></div>
                  </div>
                  <div>
                    <div><strong>Core</strong></div>
                    <div><b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('ukcore')">UK Core</b-button></div>
                    <div><b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('eucore')">EU Core</b-button></div>
                    <div><b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('intcore')">International Core</b-button></div>
                    <div><b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('uscore')">US Core</b-button></div>
                    <div><b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('dataprivacy')">Data privacy</b-button></div>
                    <div><b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('lifescience')">Life sciences</b-button></div>
                  </div>
                </b-col>
                <b-col>
                  <div><strong>Countries</strong></div>
                  <div v-for="country in countries" :key="country">
                    <b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="selectCountry(country)">{{ country }}</b-button>
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </b-col>
        </b-row>
      </b-container>
      </div>
    </b-sidebar>
  </div>
</div>
</template>

<script>
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import sourcesGroups from '@/libs/sourcesGroups.js'

export default {
  name: 'PublishersForm',
  props: ['label', 'value'],
  computed: {
  },
  created: async function () {
    this.uuid = uuidv4()
    this.sources = await this.$Amplify.API.get('cosmos', '/sources')
    this.publishersOptions = _.uniq(this.sources.map(x => x.publisher)).sort()
    this.countries = _.uniq(this.sources.map(x => x.countryName)).sort()
    if (Array.isArray(this.value)) {
      this.publishers = this.value
    } else if (this.value != null) {
      this.publishers = JSON.parse(this.value)
    }
    this.loading = false
  },
  data () {
    return {
      countries: [],
      loading: true,
      uuid: '',
      publishers: [],
      publishersOptions: [],
      visible: false
    }
  },
  methods: {
    allPublishers: function () {
      this.publishers = this.publishersOptions
    },
    selectCountry: function (country) {
      const publishers = []
      _.each(this.sources, source => {
        if (source.countryName === country) {
          publishers.push(source.publisher)
        }
      })
      this.publishers = _.uniq(_.concat(this.publishers, _.uniq(publishers)))
    },
    custom: function (selection) {
      const groups = sourcesGroups
      const newSelection = _.uniq(_.concat(this.publishers, groups[selection]))
      this.publishers = newSelection
    },
    nonePublishers: function () {
      this.publishers = []
    }
  },
  watch: {
    'publishers': function () {
      this.$emit('input', this.publishers)
    }
  }
}
</script>
