<template>
  <div>
    <div class="mb-2">
      <strong class="mr-2">{{name}}</strong>
    </div>
    <div v-if="values.length > 0">
      <div v-for="item in values" :key="'value-' + item.id">
        {{item[nameProperty]}}
        <b-button class="mx-2" variant="outline-primary" size="sm" v-on:click="deleteEdge(item)">remove</b-button>
      </div>
    </div>
    <b-form inline>
      <b-form-input class="mr-2" v-model="searchInput" placeholder="type id to find" />
      <b-button class="mx-2" variant="outline-primary" size="sm" v-on:click="search">search</b-button>
    </b-form>
    <div v-if="loading">
      <span><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></span>
    </div>
    <div v-if="searchOutput != null & !loading">
      <b-link v-on:click="assign(searchOutput)">{{searchOutput[nameProperty]}}</b-link>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'IdLookup',
  props: {
    labelDelField: String,
    labelLinkingField: String,
    labelModel: String,
    linkingModel: String,
    parentId: Number,
    parentLinkingField: String,
    linkable: {
      type: Boolean,
      default: false
    },
    linkName: {
      type: String,
      default: ''
    },
    relation: String,
    name: String,
    nameProperty: {
      type: String,
      default: 'name'
    }
  },
  /** props
  * vertexProp object { id: id, partition: partition}
  * directionProp string 'from vertex' or 'from list'
  * edgeLabelProp string - name of the edge
  * listSourceProp object { partition: partition, label: label}
  * */
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    },
    validate: function () {
      return this.selected
    }
  },
  created: function () {
    this.loadEdges()
  },
  data () {
    return {
      busy: false,
      edit: false,
      loading: true,
      searchInput: '',
      searchOutput: null,
      values: []
    }
  },
  methods: {
    search: async function () {
      this.$logger.debug('buildOptions start')
      this.loading = true
      try {
        // let response = await this.$http(config)
        const path = `/standard/${this.labelModel}/item/${this.searchInput}`
        this.searchOutput = await this.$Amplify.API.get('cosmos', path)
        console.log(this.searchOutput)
        this.loading = false
      } catch (e) {
        this.$logger.warn('getting vertices error error: ' + e)
      }
    },
    deleteEdge: async function (value) {
      this.$logger.debug('deletePropertyList started')
      this.loading = true
      try {
        // let response = await this.$http(config)
        let apiName = 'cosmos'
        let path = `/standard/relationship/${this.linkingModel}`
        let params = {
          body: {
            left: { column: this.parentLinkingField, id: this.parentId },
            right: { column: this.labelLinkingField, id: value.id }
          }
        }
        let response = await this.$Amplify.API.del(apiName, path, params)
        this.$stat.log({ action: 'unlinked property', model: this.linkingModel, payload: params.body })
        this.$logger.debug('deleted OK:', response)
        let temp = this.values
        _.pull(temp, value)
        this.values = temp
      } catch (e) {
        this.$logger.warn('delete error: ' + e)
      }
      this.loading = false
    },
    loadEdges: async function () {
      this.$logger.debug('loadEdges started')
      this.loading = true
      let labelDelField = 'no-del'
      if (this.labelDelField) {
        labelDelField = this.labelDelField
      }
      let path = `/standard/relation/${this.labelModel}/${this.parentId}/${this.relation}/${this.parentLinkingField}/${labelDelField}`
      try {
        this.values = await this.$Amplify.API.get('cosmos', path)
        this.$logger.debug('load values', this.values)
        this.loading = false
      } catch (e) {
        this.$logger.warn('/topic/:id error', e)
      }
    },
    assign: async function (item) {
      this.$logger.debug(`saveEdge start`)
      this.loading = true
      try {
        // let response = await this.$http(config)
        const apiName = 'cosmos'
        const path = `/standard/relationship/${this.linkingModel}`
        const params = {
          body: {
            left: { column: this.parentLinkingField, id: this.parentId },
            right: { column: this.labelLinkingField, id: item.id },
            username: this.user.username
          }
        }
        const response = await this.$Amplify.API.put(apiName, path, params)
        this.$stat.log({ action: 'linked new property', model: this.linkingModel, model_id: response.id, payload: response })
        this.$logger.debug('saved OK:', response)
        const newItem = {}
        newItem.id = item.id
        newItem[this.nameProperty] = item[this.nameProperty]
        this.values.push(newItem)
        this.searchOutput = null
        this.searchInput = null
      } catch (e) {
        this.$logger.warn('event saving error: ' + e)
      }
      this.loading = false
    }
  }
}
</script>

<style>
</style>
