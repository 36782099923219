<template>
  <div>
    <div class="mb-2"><strong class="mr-2">Relationships</strong></div>
    <div>
      <div><strong>Active relationships</strong></div>
      <div v-if="!busy">
        <div v-for="row in activeRelationships" :key="'activeRelationship' + row.id">
          This item {{row.relationship.name}} {{row.passive[primaryModelNameProperty]}}
          <b-button class="my-2" variant="outline-primary" size="sm" v-on:click="removeActive(row.id)">remove</b-button>
        </div>
        <b-form inline>
          <div class="mr-2">This item</div>
          <b-form-select class="mr-2" v-model="activeSelected" :options="activeOptions" />
          <div v-if="activeSelected">
            <b-form inline>
              <b-form-input class="mr-2" v-model="searchInput" placeholder="type id to find" />
              <b-button class="mx-2" variant="outline-primary" size="sm" v-on:click="searchActive">search</b-button>
            </b-form>
            <div v-if="loadingSearch">
              <span><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></span>
            </div>
            <div v-if="searchOutput != null & !loadingSearch">
              {{searchOutput[primaryModelNameProperty]}}
            </div>
          </div>
          <b-button v-if="activeSelected && populationActiveSelected" class="mr-2" variant="primary" size="sm" v-on:click="saveActive">save</b-button>
        </b-form>
      </div>
      <span v-if="busy"><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></span>
    </div>
    <div>
      <div><strong>Passive relationships</strong></div>
      <div v-if="!busy">
        <div v-for="row in passiveRelationships" :key="'passiveRelationship' + row.id">
          This item {{row.relationship.passive}} {{row.active[primaryModelNameProperty]}}
          <b-button class="my-2" variant="outline-primary" size="sm" v-on:click="removePassive(row.id)">remove</b-button>
        </div>
        <b-form inline>
          <div class="mr-2">This item</div>
          <b-form-select class="mr-2" v-model="passiveSelected" :options="passiveOptions" />
          <div v-if="passiveSelected">
            <b-form inline>
              <b-form-input class="mr-2" v-model="searchInput" placeholder="type id to find" />
              <b-button class="mx-2" variant="outline-primary" size="sm" v-on:click="searchPassive">search</b-button>
            </b-form>
            <div v-if="loadingSearch">
              <span><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></span>
            </div>
            <div v-if="searchOutput != null & !loadingSearch">
              {{searchOutput[primaryModelNameProperty]}}
            </div>
          </div>
          <b-button v-if="populationPassiveSelected" class="mr-2" variant="primary" size="sm" v-on:click="savePassive">save</b-button>
        </b-form>
      </div>
      <span v-if="busy"><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></span>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'RelationshipsInput',
  props: {
    id: Number,
    graph: String
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    if (this.graph === 'regulations') {
      this.primaryModel = 'regulation'
      this.primaryModelNameProperty = 'name_short'
      this.graphModel = 'regulationsgraph'
    }
    this.loadActive()
    this.buildOptions()
  },
  data () {
    return {
      activeOptions: [],
      activeSelected: null,
      activeRelationships: [],
      busy: false,
      graphModel: null,
      loading: true,
      loadingSearch: false,
      passiveOptions: [],
      passiveSelected: null,
      passiveRelationships: [],
      populationActiveSelected: null,
      populationPassiveSelected: null,
      primaryModel: null,
      primaryModelNameProperty: null,
      searchInput: '',
      searchOutput: {}
    }
  },
  methods: {
    buildOptions: async function () {
      this.$logger.debug('buildOptions start')
      this.busy = true
      try {
        const relationships = await this.$Amplify.API.get('cosmos', '/standard/relationship')
        const activeOptions = relationships.map(x => {
          return { value: x.id, text: x.name }
        })
        const passiveOptions = relationships.map(x => {
          return { value: x.id, text: x.passive }
        })
        this.$logger.debug('got relationships', this.vertices)
        this.activeOptions = _.concat([{ value: null, text: 'select active' }], _.sortBy(activeOptions, ['text']))
        this.passiveOptions = _.concat([{ value: null, text: 'select passive' }], _.sortBy(passiveOptions, ['text']))
        this.busy = false
        this.loading = false
      } catch (e) {
        this.$logger.warn('getting relationships error: ' + e)
      }
    },
    loadActive: async function () {
      try {
        this.activeRelationships = await this.$Amplify.API.get('cosmos', `/relationships/graph/${this.graphModel}/active/id/${this.id}`)
        this.passiveRelationships = await this.$Amplify.API.get('cosmos', `/relationships/graph/${this.graphModel}/passive/id/${this.id}`)
        this.busy = false
        this.loading = false
      } catch (e) {
        this.$logger.warn('getting relationships error: ' + e)
      }
    },
    removeActive: async function (id) {
      try {
        this.busy = true
        await this.$Amplify.API.del('cosmos', `/standard/${this.graphModel}/${id}`)
        _.remove(this.activeRelationships, x => x.id === id)
        this.busy = false
        this.loading = false
      } catch (e) {
        this.$logger.warn('getting relationships error: ' + e)
      }
    },
    removePassive: async function (id) {
      try {
        this.busy = true
        await this.$Amplify.API.del('cosmos', `/standard/${this.graphModel}/${id}`)
        _.remove(this.passiveRelationships, x => x.id === id)
        this.busy = false
        this.loading = false
      } catch (e) {
        this.$logger.warn('getting relationships error: ' + e)
      }
    },
    saveActive: async function () {
      try {
        const body = {
          active_id: this.id,
          passive_id: this.populationActiveSelected,
          relationship_id: this.activeSelected,
          username: this.user.username
        }
        await this.$Amplify.API.put('cosmos', `/standard/${this.graphModel}`, { body })
        this.activeRelationships = await this.$Amplify.API.get('cosmos', `/relationships/graph/${this.graphModel}/active/id/${this.id}`)
        this.searchOutput = null
        this.searchInput = null
        this.activeSelected = null
        this.populationActiveSelected = null
        this.busy = false
        this.loading = false
      } catch (e) {
        this.$logger.warn('getting relationships error: ' + e)
      }
    },
    savePassive: async function () {
      try {
        const body = {
          active_id: this.populationPassiveSelected,
          passive_id: this.id,
          relationship_id: this.passiveSelected,
          username: this.user.username
        }
        await this.$Amplify.API.put('cosmos', `/standard/${this.graphModel}`, { body })
        this.passiveRelationships = await this.$Amplify.API.get('cosmos', `/relationships/graph/${this.graphModel}/passive/id/${this.id}`)
        this.searchInput = null
        this.searchOutput = null
        this.passiveSelected = null
        this.populationPassiveSelected = null
        this.busy = false
        this.loading = false
      } catch (e) {
        this.$logger.warn('getting relationships error: ' + e)
      }
    },
    searchActive: async function () {
      this.$logger.debug('buildOptions start')
      this.loadingSearch = true
      try {
        // let response = await this.$http(config)
        const path = `/standard/${this.primaryModel}/item/${this.searchInput}`
        this.searchOutput = await this.$Amplify.API.get('cosmos', path)
        this.populationActiveSelected = this.searchOutput.id
        this.loadingSearch = false
      } catch (e) {
        this.$logger.warn('getting vertices error error: ' + e)
      }
    },
    searchPassive: async function () {
      this.$logger.debug('buildOptions start')
      this.loadingSearch = true
      try {
        // let response = await this.$http(config)
        const path = `/standard/${this.primaryModel}/item/${this.searchInput}`
        this.searchOutput = await this.$Amplify.API.get('cosmos', path)
        this.populationPassiveSelected = this.searchOutput.id
        this.loadingSearch = false
      } catch (e) {
        this.$logger.warn('getting vertices error error: ' + e)
      }
    }
  }
}
</script>

<style>
</style>
