<template>
  <div>
    <div class="mb-2"><strong>Links</strong></div>
    <div v-if="!saving">
      <div v-for="link in links" :key="'link'+link.id" class="mb-3">
        <div>
          {{link.name}}
          <b-button class="mx-4" variant="outline-primary" size="sm" v-on:click="editStart(link)">edit</b-button>
          <b-button class="mr-2" variant="outline-primary" size="sm" v-on:click="remove(link.id)">remove</b-button>
        </div>
        <div><a :href="link.url" target="_blank">{{link.url}}</a></div>
      </div>
      <div>
        <b-form-input id="inputName"
          v-model="nameInput"
          placeholder="Link name"
          required
          class="mb-2">
        </b-form-input>
        <b-form-input id="inputUrl"
          v-model="urlInput"
          placeholder="https://www.example.com"
          required
          class="mb-2">
        </b-form-input>
      </div>
      <b-button v-if="validate" class="mr-2" variant="primary" size="sm" v-on:click="save">Save</b-button>
      <b-button v-if="nameInput" variant="outline-primary" size="sm" v-on:click="cancel">Cancel</b-button>
    </div>
    <span v-if="saving">
      <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
    </span>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'LinksComponent',
  props: ['model', 'parentId', 'property'],
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    },
    validate: function () {
      return this.nameInput && this.urlInput
    }
  },
  created: function () {
    this.load()
  },
  data () {
    return {
      edit: false,
      id: null,
      links: [],
      nameInput: null,
      saving: false,
      urlInput: null
    }
  },
  methods: {
    cancel: function () {
      this.nameInput = null
      this.urlInput = null
    },
    editStart: function (link) {
      this.id = link.id
      this.nameInput = link.name
      this.urlInput = link.url
      this.edit = true
    },
    load: async function () {
      this.saving = true
      try {
        this.links = await this.$Amplify.API.get('cosmos', `/standard/${this.model}/where/${this.property}/equal/${this.parentId}`)
      } catch (e) {
        this.$logger.warn(e)
      }
      this.saving = false
    },
    remove: async function (id) {
      this.saving = true
      try {
        await this.$Amplify.API.del('cosmos', `/standard/${this.model}/${id}`)
        const links = this.links
        _.remove(links, x => x.id === id)
        this.links = links
      } catch (e) {
        this.$logger.warn('event saving error: ' + e)
      }
      this.saving = false
    },
    save: async function () {
      this.$logger.debug(`saving start`)
      this.saving = true
      try {
        // let response = await this.$http(config)
        const body = {
          name: this.nameInput,
          url: this.urlInput
        }
        body[this.property] = this.parentId
        let params = {
          body: body
        }
        let response
        if (this.edit) {
          await this.$Amplify.API.put('cosmos', `/standard/${this.model}/${this.id}`, params)
          const newItem = {
            id: this.id,
            name: this.nameInput,
            url: this.urlInput
          }
          const links = this.links
          _.remove(links, x => x.id === this.id)
          links.push(newItem)
          this.links = links
        } else {
          response = await this.$Amplify.API.put('cosmos', `/standard/${this.model}`, params)
          this.links.push(response)
        }
        this.cancel()
      } catch (e) {
        this.$logger.warn('event saving error: ' + e)
      }
      this.edit = false
      this.id = null
      this.saving = false
    }
  }
}
</script>

<style>
</style>
