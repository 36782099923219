<template>
  <div>
    <div class="mb-2">
      <strong class="mr-2">Sectors</strong>
    </div>
    <div class="mb-2">
      <span v-if="!loading">
        <div v-for="value in values" :key="value.id">
            {{value.sector}} | {{value.industrygroup}} | {{value.industry}} | {{value.subindustry}}
          <b-button variant="outline-primary" size="sm" v-on:click="deleteEdge(value)">remove</b-button>
        </div>
      </span>
      <span v-if="loading">
        <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
      </span>
    </div>
    <div>
      <span v-if="!busy">
        <b-form-group id="select-industry-group" label="Sector" label-for="input-sector">
          <b-form-select id="input-sector" v-model="form.sector" :options="sectorOptions"></b-form-select>
        </b-form-group>
        <b-form-group id="select-industrygroup-group" label="Industry group" label-for="input-industrygroup" v-if="industryGroupOptions">
          <b-form-select id="input-industrygroup" v-model="form.industrygroup" :options="industryGroupOptions"></b-form-select>
        </b-form-group>
        <b-form-group id="select-industry-group" label="Industry" label-for="input-industry" v-if="industryOptions">
          <b-form-select id="input-industry" v-model="form.industry" :options="industryOptions"></b-form-select>
        </b-form-group>
        <b-form-group id="select-subindustry-group" label="Sub-industry" label-for="input-subindustry" v-if="subIndustryOptions">
          <b-form-select id="input-subindustry" v-model="form.subindustry" :options="subIndustryOptions"></b-form-select>
        </b-form-group>
        <b-button v-if="validate" class="mr-2" variant="primary" size="sm" v-on:click="saveEdge">save</b-button>
        <b-button v-if="validate" class="mr-2" variant="outline-primary" size="sm" v-on:click="clear">clear</b-button>
      </span>
      <span v-if="busy"><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></span>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import industries from '@/libs/industries.js'

export default {
  name: 'SelectSectors',
  props: {
    parentId: Number,
    parentLinkingField: String,
    parentModel: String,
    sectorModel: String
  },
  computed: {
    sectorOptions: function () {
      let results = industries.map(x => x.sector)
      results = _.uniq(results)
      return results
    },
    industryGroupOptions: function () {
      if (!this.form.sector) {
        return null
      }
      const industryGroups = industries.filter(x => x.sector === this.form.sector)
      if (!Array.isArray(industryGroups)) {
        return null
      }
      if (industryGroups.length === 0) {
        return null
      }
      let results = industryGroups.map(x => x.industryGroup)
      results = _.uniq(results)
      return results
    },
    industryOptions: function () {
      if (!this.form.sector) {
        return null
      }
      if (!this.form.industrygroup) {
        return null
      }
      if (!this.industryGroupOptions) {
        return null
      }
      const industry = industries.filter(x => x.sector === this.form.sector && x.industryGroup === this.form.industrygroup)
      if (!Array.isArray(industry)) {
        return null
      }
      if (industry.length === 0) {
        return null
      }
      let results = industry.map(x => x.industry)
      results = _.uniq(results)
      return results
    },
    subIndustryOptions: function () {
      if (!this.form.sector) {
        return null
      }
      if (!this.form.industrygroup) {
        return null
      }
      if (!this.form.industry) {
        return null
      }
      if (!this.industryOptions) {
        return null
      }
      const industryGroups = industries.filter(x => x.sector === this.form.sector && x.industryGroup === this.form.industrygroup && x.industry === this.form.industry)
      if (!Array.isArray(industryGroups)) {
        return null
      }
      if (industryGroups.length === 0) {
        return null
      }
      let results = industryGroups.map(x => x.subIndustry)
      return results
    },
    user: {
      get () {
        return this.$store.state.user
      }
    },
    validate: function () {
      return this.form.sector
    }
  },
  created: function () {
    this.loadEdges()
  },
  data () {
    return {
      busy: false,
      form: {
        sector: null,
        industrygroup: null,
        industry: null,
        subindustry: null
      },
      loading: true,
      values: []
    }
  },
  methods: {
    clear: function () {
      this.form = {
        sector: null,
        industrygroup: null,
        industry: null,
        subindustry: null
      }
    },
    deleteEdge: async function (value) {
      this.$logger.debug('deletePropertyList started')
      this.busy = true
      try {
        let response = await this.$Amplify.API.del('cosmos', `/standard/${this.sectorModel}/${value.id}`)
        this.$stat.log({ action: 'deleted sector', model: this.sectorModel })
        this.$logger.debug('deleted OK:', response)
        let temp = this.values
        _.pull(temp, value)
        this.values = temp
      } catch (e) {
        this.$logger.warn('delete error: ' + e)
      }
      this.busy = false
    },
    loadEdges: async function () {
      this.$logger.debug('loadEdges started')
      this.busy = true
      try {
        this.values = await this.$Amplify.API.get('cosmos', `/standard/sectors/${this.sectorModel}/${this.parentId}/${this.parentLinkingField}`)
        this.$logger.debug('load values', this.values)
        this.loading = false
        this.busy = false
      } catch (e) {
        console.log(e)
      }
    },
    saveEdge: async function () {
      this.$logger.debug(`saveEdge start`)
      this.busy = true
      try {
        // let response = await this.$http(config)
        const apiName = 'cosmos'
        const path = `/standard/${this.sectorModel}`
        const params = {
          body: {
            ...this.form
          }
        }
        params.body[this.parentLinkingField] = this.parentId
        console.log(params)
        const response = await this.$Amplify.API.put(apiName, path, params)
        this.$stat.log({ action: 'linked new property', model: this.linkingModel, model_id: response.id, payload: response })
        this.$logger.debug('saved OK:', response)
        this.values.push(response)
      } catch (e) {
        this.$logger.warn('event saving error: ' + e)
      }
      this.busy = false
    }
  }
}
</script>

<style>
</style>
